import React from "react";
import SEO from "../components/seo";
import FunctionPageLayout from "../components/functionPageLayout";
import ResponsiveEmbed from "react-responsive-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";

const Wywiady = () => {
  return (
    <FunctionPageLayout
      title="Wywiady z ekspertami na temat zarządzania firmą za pomocą systemów informatycznych"
      headline="Rozmowy ukazujące punkt widzenia praktyków branżowych"
    >
      <SEO
        title="Wywiady z ekspertami na temat zarządzania firmą za pomocą systemów informatycznych"
        description="Wywiady z ekspertami na temat zarządzania firmą za pomocą systemów informatycznych ✅ Materiały video • Wszystko, co chcielibyście wiedzieć"
      />
      

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
          paddingTop: 5,
        }}>
              <strong>
                Czym jest analiza przedwdrożeniowa? Dlaczego to&nbsp;must-have wdrożenia systemu dedykowanego?
              </strong>
            </p>
            <p>
              W trzecim odcinku serii naszych wywiadów zwracamy uwagę na&nbsp;fundamentalny proces 
              wdrożenia systemu jakim jest analiza przedwdrożeniowa. 
              Skupiamy się przede wszystkim na najistotniejszych elementach analizy biznesowej, 
              jej wyzwaniach oraz&nbsp;znaczeniu we&nbsp;wdrażaniu systemów informatycznych.
            <br />Rozmawiamy z Wojciechem Nosalem – Business Unit Managerem w Questy, 
            który&nbsp;posiada wieloletnie doświadczenie w&nbsp;przeprowadzaniu analiz przedwdrożeniowych.
Mamy nadzieję, że&nbsp;skutecznie rozwiejemy wszystkie Twoje&nbsp;wątpliwości!
</p>


                        <div
              style={{
                textAlign: "left",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/analiza-przedwdrozeniowa/"
              >
                Przeczytaj wywiad
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                paddingTop: "50px",
              }}>
            <LiteYouTubeEmbed
          poster="maxresdefault"
          id="xqZlfUHuoG4"
          title="Czym jest analiza przedwdrożeniowa? Dlaczego to&nbsp;must-have wdrożenia systemu dedykowanego?"
        />
          </div>
        </div>
      </Container>



      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
          paddingTop: 5,
        }}>
              <strong>
                Co to jest system CRM: czy sama instalacja&nbsp;wystarczy?<br />Rozmowa z&nbsp;CEO Questy, Łukaszem Tadyszakiem
              </strong>
            </p>
            <p>
              W pierwszym odcinku naszej serii wywiadów na&nbsp;temat sprzedaży, nowoczesnej technologii i&nbsp;obsługi klienta rozmawiamy
              z CEO firmy&nbsp;Questy, Łukaszem Tadyszakiem. Poruszamy szereg tematów związanych z&nbsp;rozwiązaniami CRM – czym właściwie
              są systemy CRM i&nbsp;jakie są różnice między konkurencyjnymi rozwiązaniami? Jakie korzyści niosą ze sobą tego typu oprogramowania
              i co należy zrobić, żeby ich wdrożenie w firmie zakończyło się sukcesem? Obejrzyj nasz wywiad już teraz i&nbsp;poznaj odpowiedzi
              na wszystkie te pytania i&nbsp;wiele innych!
            </p>
                        <div
              style={{
                textAlign: "left",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/co-to-jest-crm/"
              >
                Przeczytaj wywiad
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                paddingTop: "50px",
              }}>
            <LiteYouTubeEmbed
          poster="maxresdefault"
          id="uqoYcMSRL9A"
          title="Co to jest CRM: sama instalacja WYSTARCZY? Wywiad z Łukaszem Tadyszakiem"
        />
          </div>
        </div>
      </Container>
         <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
          paddingTop: 5,
        }}>
              <strong>
                Porażki wdrożeń CRM: dlaczego się nie udaje?<br />Rozmowa z&nbsp;CEO Questy, Łukaszem Tadyszakiem
              </strong>
            </p>
            <p>
              W kolejnym odcinku naszej serii wywiadów na&nbsp;temat sprzedaży, nowoczesnej technologii i&nbsp;obsługi klienta ponownie rozmawiamy
              z CEO firmy Questy, Łukaszem Tadyszakiem. Dużo mówi się o sukcesach wdrożeń systemów informatycznych w przedsiębiorstwach. My natomiast postanowiliśmy poruszyć temat tabu i nagraliśmy odcinek o 
              porażkach wdrożeń CRM. Co jest najczęstszym powodem nieudanych wdrożeń? Co zrobić, aby ustrzec się przed tą sytuacją?   </p>
                        <div
              style={{
                textAlign: "left",
                margin: "10px",
                padding: "10px",
              }}
            >
              <Link
                className={styles.startNow2}
                to="/wdrozenie-crm"
              >
                Przeczytaj wywiad
              </Link>
            </div>
          </div>
          <div className="text-block" style={{
                paddingTop: "50px",
              }}>
            <LiteYouTubeEmbed
          poster="maxresdefault"
          id="IFcregJWj8c"
          title="porażki wdrożeń CRM jak się ustrzec"
        />
          </div>
        </div>
      </Container>
    </FunctionPageLayout>
  );
};

export default Wywiady;
